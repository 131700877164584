import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Paper,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { ApiClient, ExamStatsViewModel } from "../ApiHelper";
import ExamList from "./recording/ExamList";

function RecordingIndex() {
  const [exams, setExams] = useState<null | ExamStatsViewModel[]>(null);

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    (async () => {
      const exams = await ApiClient.getExams();

      setExams(exams);
    })();
  }, []);

  if (exams === null) {
    return <CircularProgress />;
  }

  const search = async () => {
    setExams(null);

    const exams = await ApiClient.getExams(searchText);

    setExams(exams);
  };

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit">
          Optagelser
        </Link>
      </Breadcrumbs>

      <Paper elevation={1}>
        <Grid
          container
          alignItems="center"
          sx={{
            p: 1,
            mb: 1,
          }}
        >
          <Grid item>
            <TextField
              label="Søg"
              variant="outlined"
              value={searchText}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  search();
                  ev.preventDefault();
                }
              }}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Button onClick={search}>Søg</Button>
          </Grid>
          <Grid item sx={{ flexGrow: 1 }}>
            <Box display="flex" justifyContent="flex-end">
              <Button onClick={() => (window.location.href = "/export/exam")}>
                Eksporter deltagerlister
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <ExamList exams={exams} searchText={searchText}/>
    </>
  );
}

export default RecordingIndex;
