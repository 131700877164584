import { BookmarkModel, SessionModel } from "../Api";

export function BookmarkList(props: {
  bookmarks: BookmarkModel[];
  onGoToTimestamp: (positionInSeconds: number) => void;
}) {
  return (
    <>
      {props.bookmarks.map((b) => {
        return (
          <div key={b.id}>
            <BookmarkView bookmark={b} onClick={() => props.onGoToTimestamp(b.positionInSeconds || 0)} />
        </div>
        );
      })}
    </>
  );
}

export function BookmarkView(props: {
  bookmark: BookmarkModel;
  onClick: () => void;
}) {
  const b = props.bookmark;
  const positionInSeconds = props.bookmark.positionInSeconds || 0;

  const prettyTimestamp = Math.floor(positionInSeconds / 60).toString() + ":" + (positionInSeconds % 60).toString();

  return (
    <div>
      <span><a href="javascript:void(0)" onClick={props.onClick}>{prettyTimestamp}</a> {b.reviewerName}</span>: 
      <div style={{ fontStyle: "italic", whiteSpace: "pre-line"}}>{b.comment}
      </div>
    </div>
  );
}
