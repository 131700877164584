import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { SessionModel } from "../Api";
import BookmarkAdd from "@mui/icons-material/BookmarkAdd";
import LoadingButton from '@mui/lab/LoadingButton';
import { ApiClient } from "../ApiHelper";


export function AddBookmarkButtonAndDialog(props: {
  session: SessionModel;
  progress: number;
  onAdded?: () => void,
  onOpen?: () => void
}) {
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [timestamp, setTimestamp] = useState("");

  const [saving, setSaving] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setSaving(false);
    setComment("");
  }

  const showDialog = () => {
    setTimestamp(
      Math.floor(props.progress / 60).toString() +
        ":" +
        Math.floor(props.progress % 60).toString()
    );

    setOpen(true);

    if(props.onOpen) props.onOpen();
  };

  const save = () => {
    setSaving(true);




    const saveData = async () => {
        let positionInSeconds = 0;

        var splitTimestamp = timestamp.split(':');

        if(splitTimestamp.length > 2)
        {
            alert('Tidsstempel format skal være TT:mm');

            setSaving(false);
            return;
        }

        for(let i = 0; i < splitTimestamp.length; i++)
        {
            let value = parseInt(splitTimestamp[i]) || 0;

            let pow = splitTimestamp.length - i - 1;

            positionInSeconds += Math.pow(60, pow) * value;
        }

        await ApiClient.createBookmark(props.session.id || 0 , comment, positionInSeconds);
     
        if(props.onAdded) props.onAdded();

        handleClose();
    };

    saveData().catch(() => { alert('Der skete en fejl'); setSaving(false)});
     
  }

  return (
    <>
      <Tooltip title="Sæt bogmærke">
        <ToggleButtonGroup>
          <ToggleButton value="32%" onClick={showDialog}>
            <BookmarkAdd />
          </ToggleButton>
        </ToggleButtonGroup>
      </Tooltip>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Sæt bogmærke</DialogTitle>
        <DialogContent sx={{ width: '600px'}}>
          <DialogContentText>
            Tilføj et bogmærke til denne optagelse.
          </DialogContentText>

          <Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                autoFocus
                id="name"
                multiline
                label="Kommentar"
                fullWidth
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                variant="standard"
              />
            </Box>
            <Box sx={{ mt: 4 }}>
              <TextField
                label="Tidspunkt i optagelse"
                value={timestamp}
                onChange={(e) => setTimestamp(e.target.value)}
              ></TextField>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuller</Button>
          <LoadingButton onClick={save} loading={saving} variant="outlined">Opret bogmærke</LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
