import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  createTheme,
  CssBaseline,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@mui/material";
import ChatIndex from "./routes/ChatIndex";
import AppContext, { IAppContext } from "./AppContext";
import { useEffect, useState } from "react";
import { ApiClient } from "./ApiHelper";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  Link as RouterLink,
} from "react-router-dom";
import RecordingIndex from "./routes/RecordingIndex";
import RecordingExam from "./routes/RecordingExam";
import StreamIndex from "./routes/stream/StreamIndex";
import {
  RecordingExaminee,
  RecordingSessionView,
} from "./routes/RecordingExaminee";
import { DistributeReviewersRoute } from "./routes/DistributeReviewers";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import FaceCompareIndex from "./routes/FaceCompareIndex";

function App() {
  var theme = createTheme({
    palette: {
      primary: {
        main: "#8A181B", // KU color
      },
      secondary: {
        main: "#8A181B", // KU color
      },
      background: {
        default: "#eee",
      },
    },
    typography: {
      fontSize: 12,
    },
  });

  const [userSettings, setUserSettings] = useState<IAppContext | null>(null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    (async () => {
      try {
        var result = await ApiClient.whoAmI();

        if (result != null) {
          setUserSettings({
            name: result.name || "",
            reviewerId: result.reviewerId || 0,
          });
        }
      } catch {
        if (window.location.href.indexOf("login?redirectUrl") !== -1) {
          setErrorMessage("Kunne ikke logges ind...");

          return;
        }

        window.location.href =
          "/login?redirectUrl=" + encodeURIComponent(window.location.href);
      }
    })();
  }, []);

  if (errorMessage) {
    return (
      <>
        {errorMessage}
        <br />
        <br />
        <a
          href={
            new URLSearchParams(window.location.search).get("redirectUrl") ||
            "/"
          }
        >
          Prøv igen
        </a>
      </>
    );
  }

  if (userSettings === null) {
    return <CircularProgress />;
  }

  return (
    <AppContext.Provider value={userSettings}>
      <DndProvider backend={HTML5Backend}>
        {/* All other components are wrapped by the AppContext.Provider */}
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />

            <Box sx={{ flexGrow: 1 }}>
              <AppBar position="static">
                <Toolbar>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    <Button
                      sx={{ color: "white" }}
                      to="/app/chat"
                      component={RouterLink}
                    >
                      ITX Flex Remote
                    </Button>
                    <Button
                      sx={{ color: "white" }}
                      to="/app/chat"
                      component={RouterLink}
                    >
                      Chat
                    </Button>

                    <Button
                      sx={{ color: "white" }}
                      to="/app/recordings"
                      component={RouterLink}
                    >
                      Optagelser
                    </Button>
                    
                    <Button
                      sx={{ color: "white" }}
                      to="/app/facecompare"
                      component={RouterLink}
                    >
                      ID-kort godkendelse
                    </Button>

                    <Button
                      sx={{ color: "white" }}
                      to="/app/stream"
                      component={RouterLink}
                    >
                      Stream
                    </Button>
                  </Typography>
                </Toolbar>
              </AppBar>
            </Box>
            <Box sx={{ p: 1 }}>
              <Routes>
                <Route path="/" element={<Navigate to="/app/chat" />} />
                <Route path="/app" element={<Navigate to="/app/chat" />} />
                <Route path="/app/chat" element={<ChatIndex />} />
                <Route path="/app/stream" element={<StreamIndex />} />
                <Route
                  path="/app/exam/:examId/reviewers/distribute"
                  element={<DistributeReviewersRoute />}
                />
                <Route path="/app/recordings" element={<RecordingIndex />} />
                <Route path="/app/facecompare" element={<FaceCompareIndex />} />
                <Route path="/app/recordings/:id" element={<RecordingExam />} />
                <Route
                  path="/app/recordings/examinee/:examineeId"
                  element={<RecordingExaminee />}
                />
                <Route
                  path="/app/recordings/session/:sessionId"
                  element={<RecordingSessionView />}
                />

                <Route path="*" element={<h2>404 - route not found</h2>} />
              </Routes>
            </Box>
          </ThemeProvider>
        </BrowserRouter>
      </DndProvider>
    </AppContext.Provider>
  );
}

export default App;
