import { Chip } from "@mui/material";
import { ChatRoleViewModel } from "../../ApiHelper";

function ChatRoleView(props: { value: ChatRoleViewModel }) {

  var text = props.value.name;

  if(!props.value.show)
  {
      return <></>;
  }

  return <Chip label={text} style={{backgroundColor:props.value.backgroundColor, color: props.value.color}} size="small" />;
}

export default ChatRoleView;
