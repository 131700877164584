import { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import { Box, TextField, Theme, createStyles, makeStyles } from "@mui/material";
import ChatStatusFields from "./ChatStatusFields";
import { ApiClient, FullChatViewModel } from "../../ApiHelper";

function Chat(props: { chat: FullChatViewModel }) {
  const chat = props.chat?.chat;

  if (!chat) {
    throw new Error("Chat not found");
  }

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const sendMessage = async () => {
    if (text.trim()) {
      await ApiClient.sendChatMessage(chat.id, text);

      setText("");
    }
    scrollToBottom();
  };

  const [text, setText] = useState("");

  const scrollToBottom = (behavior: ScrollBehavior = "smooth") => {
    messagesEndRef?.current?.scrollIntoView({ behavior: behavior });
  };

  useEffect(() => {
    let t1 = setTimeout(scrollToBottom, 100);
    scrollToBottom("auto");

    return () => clearTimeout(t1);
  }, [chat]);

  let format = "yyyy-MM-dd";
  let dates = props.chat.messages.map((m) => m.sent.toFormat(format));

  dates.sort();

  dates = unique(dates);

  function unique(arr: any) {
    var u : any = {}, a = [];
    for(var i = 0, l = arr.length; i < l; ++i){
        if(!u.hasOwnProperty(arr[i])) {
            a.push(arr[i]);
            u[arr[i]] = 1;
        }
    }
    return a;
}

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            p: 2,
            borderBottom: "2px solid #ddd",
            width: "100%",
          }}
        >
          <strong>
            {chat.name} ({chat.username})
          </strong>

          <Box
            sx={{
              pt: 1,
            }}
          >
            <ChatStatusFields chat={props.chat} />
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1, width: "100%", overflowY: "auto" }}>
          {dates.map((date) => (
            <Box key={date}>
              <DividerWithText><span>{date}</span></DividerWithText>

              {props.chat.messages
                ?.filter((x) => x.sent.toFormat(format) === date)
                .map((m) => (
                  <Box
                    key={m.id}
                    sx={{
                      fontSize: "90%",
                      p: 2,
                      m: 2,
                      borderRadius: 2,
                      color: m.isEmployee ? "secondary.contrastText" : "text.primary",
                      backgroundColor: m.isEmployee ? "primary.main" : "#eee",
                      marginRight: !m.isEmployee ? 2 : "20%",
                      marginLeft: m.isEmployee ? 2 : "20%",
                    }}
                  >{date}
                    <Box sx={{ whiteSpace: "pre-line", overflowWrap: "break-word" }}>{m.text}</Box>

                    <Box sx={{ pt: 1, fontSize: "80%", fontStyle: "italic" }}>
                      {m.name + " " + m.username} kl. {m.sent.toFormat("HH:mm")}
                    </Box>
                  </Box>
                ))}
            </Box>
          ))}

          <div ref={messagesEndRef}></div>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ p: 2, display: "flex" }}>
            <TextField value={text} id="filled-textarea" multiline variant="filled" fullWidth onChange={(event) => setText(event.target.value)} />

            <Button onClick={sendMessage}>Send</Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}




function DividerWithText(props: { children: JSX.Element }) {
    
    const container ={
        display: "flex",
        alignItems: "center",
        margin: '2em'
      };
 return (
  <div style={container}>
    <div style={{
        borderBottom: "2px solid lightgray",
        flexGrow: 1,
      }} />
    <span style={{
        paddingRight: '1em',
        paddingLeft: '1em',
        fontWeight: 500,
        fontSize: 22,
        color: "lightgray"
      }}>{props.children}</span>
    <div style={{
        borderBottom: "2px solid lightgray",
        flexGrow: 1,
      }} />
  </div>
 );
};

export default Chat;
