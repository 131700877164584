import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { ApiClient } from "../../ApiHelper";

function ChatIntroduktionstekstEdit() {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState<string | undefined>(undefined);

  const saveAndClose = async () => {
    if (text !== undefined) {
      await ApiClient.setChatIntroductionText(text);

      setOpen(false);
    }
  };

  const openClick = async () => {
    setText(undefined);

    setOpen(true);

    var result = await ApiClient.getChatIntroductionText();

    setText(result.introductionText);
  };

  let dialogContent = (
    <DialogContent>
      <CircularProgress />
    </DialogContent>
  );

  if (text !== undefined) {
    dialogContent = (
      <>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            rows={5}
            autoFocus
            label="Skriv introduktionstekst til ansøgere her"
            value={text || ""}
            variant="filled"
            onChange={(event) => setText(event.target.value)}
          ></TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Annuller</Button>
          <Button onClick={saveAndClose}>Gem</Button>
        </DialogActions>
      </>
    );
  }

  return (
    <>
      <Button onClick={openClick}>Rediger introduktionstekst</Button>

      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        {dialogContent}
      </Dialog>
    </>
  );
}

export default ChatIntroduktionstekstEdit;
