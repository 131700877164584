import { useState, useEffect } from "react";
import Chat from "./Chat";
import { ApiClient, ChatViewModel, FullChatViewModel, getChatHubConnection } from "../../ApiHelper";

function ChatLoad(props: { chat: ChatViewModel }) {
  const [fullChat, setFullChat] = useState<FullChatViewModel | null>(null);

  const refresh = async () => {
    var c = await ApiClient.getFullChat(props.chat.id);
    console.log("setFullChat");
    setFullChat(c);
  }

  useEffect(() => {
    refresh();

    var hubConnection = getChatHubConnection();

    const methodName = "chat-recordinguser-" + props.chat.recordingUserId;

    console.log(methodName);
    hubConnection.on(methodName, () => {
      console.log("Received chat message");

      refresh();
    }); 

    hubConnection.start().catch(e => {
        alert('Failed to start hub connection: ' + e);
    });

    return () => {
      hubConnection.stop();
    };
  }, [props.chat.recordingUserId]);

  if (fullChat === null) {
    return <>Loading</>;
  }

  return <Chat chat={fullChat} />;
}

export default ChatLoad;
