import CircularProgress from "@mui/material/CircularProgress";
import { ApiClient } from "../ApiHelper";
import { useEffect, useState } from "react";
import { LogSession } from "../Api";
import Paper from "@mui/material/Paper/Paper";
import Box from "@mui/material/Box/Box";
import { DateTime } from "luxon";
import { SettingsAccessibilityRounded } from "@mui/icons-material";

export function ExamineeLog(props: { examineeId: number }) {
  const [logSessions, setLogSessions] = useState<LogSession[] | null>(null);

  const load = async () => {
    setLogSessions((await ApiClient.getExamineeLogs(props.examineeId)).sessions || []);
  };

  useEffect(() => {
    load();
  }, []);

  if (logSessions == null) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box sx={{ p: 2, mb: 1 }}>
        {logSessions.map((session) => (
          <Paper key={session.id} elevation={1} sx={{ p: 2, mb: 1 }}>
            <b>{DateTime.fromISO(session.created || "").toFormat(" d/M kl. HH:mm:ss")}</b>

            {session.systemCheck && <>
                <span> (systemcheck {
                    session.systemCheckCompleted && <>
                       afsluttet: {DateTime.fromISO(session.systemCheckCompleted || "").toFormat(" d/M kl. HH:mm:ss")}
                    </>
                    }
                )</span>
            </>
            }
            <pre style={{ fontSize: "80%" }}>{session.logText}</pre>
          </Paper>
        ))}
      </Box>
    </>
  );
}
