import { DataGrid } from "@mui/x-data-grid/DataGrid/DataGrid";
import { GridValueGetterParams } from "@mui/x-data-grid/models/params/gridCellParams";
import { ExamStatsViewModel } from "../../ApiHelper";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper/Paper";
import { Box, Breadcrumbs, Button, Grid, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";

function ExamList(props: { exams: ExamStatsViewModel[], searchText: string }) {
  const navigate = useNavigate();

  function handleClick(id: number) {
    navigate("/app/recordings/" + id + "?searchText=" + encodeURIComponent(props.searchText));
  }

  return (
    <>

    <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Navn</TableCell>
            <TableCell>Start</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Uddannelse</TableCell>
            <TableCell align="right">Deltagere</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.exams.map((row) => (
            <TableRow
              key={row.exam.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              
            onClick={() => handleClick(row.exam.id)}
            >
            <TableCell component="th" scope="row">
              {row.exam.name}
            </TableCell>
              <TableCell component="th" scope="row">
                {row.exam.start.toFormat("d/M kl. HH:mm")}
              </TableCell>
            <TableCell component="th" scope="row">
              {row.exam.type}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.exam.education}
            </TableCell>
              <TableCell align="right">{row.examineeCount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      
      </TableContainer>
    </>
  );
}

export default ExamList;
