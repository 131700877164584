import { HubConnection } from "@microsoft/signalr";
import { ApiClient, getStreamingHubConnection } from "../../ApiHelper";

type partnerConnectionId = string;

export class ConnectionHolder {
  constructor(
    public partnerId: string,
    public connection: RTCPeerConnection,
    public videostreams: MediaStream[],
    public audiostreams: MediaStream[],
    public sessionId: number,
    public username: string,
    public name: string,
    public recordingUserId: number,
    private onDisconnectAndUnassign: () => void,
    private onRestartConnection: () => void
  ) {}

  static connectionHolderIdNext = 1;

  public connectionHolderId = ConnectionHolder.connectionHolderIdNext++;

  isVisible = () => this.videostreams.some((x) => !x.getTracks()[0].muted);

  stop = () => {
    this.connection?.close();
  };

  disconnectAndUnassign = () => {
    this.onDisconnectAndUnassign();
  };

  restartConnection = () => {
    this.onRestartConnection();
  };
}

export interface IPendingHolder {
  connectionId: string;
  sessionId: number;
  username: string;
  name: string;
  recordingUserId: number;
}
export class WebRtcConnectionController {
  constructor() {
    this.initialize();
  }

  rtcIceServers: RTCIceServer[] = [];

  private initialize = async () => {
    let configuration = await ApiClient.api.streaming.webrtcconfigurationList();

    this.rtcIceServers =
      configuration.rtcIceServers?.map((i) => {
        return {
          urls: i.urls || [],
          username: i.username || undefined,
          credential: i.password || undefined,
        };
      }) || [];
  };

  public tryConnectToPending = (pending: IPendingHolder) => {
    this.getConnection(pending.connectionId, pending.username, pending.name, pending.sessionId, pending.recordingUserId);

    this.hubConnection?.invoke("startStreaming", pending.connectionId);
  };

  public connectionsUpdated: ((connections: ConnectionHolder[]) => void) | null = (_) => {};

  public stopStreamingBadConnection: ((sessionId: number) => void) | null = (_) => {};

  public pendingUpdated: ((pendingUsers: IPendingHolder[]) => void) | null = (_) => {};

  private hubConnection: HubConnection | null = null;

  private pendingUsers: IPendingHolder[] = [];

  private connectionDictionary: {
    [id: string]: ConnectionHolder;
  } = {};

  private getConnections = () => Object.entries(this.connectionDictionary).map((e) => e[1]);
  private sendConnectionsUpdated = () => this.connectionsUpdated?.(this.getConnections().map((c) => c));
  private sendPendingUpdated = () => this.pendingUpdated?.(this.pendingUsers);

  private getConnection = (partnerId: partnerConnectionId, username?: string, name?: string, sessionId?: number, recordingUserId?: number) => {
    if (this.connectionDictionary[partnerId]) {
      return this.connectionDictionary[partnerId];
    }

    console.log("Connection to " + partnerId + " is being created");

    if (!sessionId) {
      throw "Session id must be set on construction";
    }

    if (!recordingUserId) {
      throw "recordingUserId must be set on construction";
    }

    if (!username) {
      throw "Username must be set on construction";
    }

    if (!name) {
      throw "Name must be set on construction";
    }

    if (!this.rtcIceServers.length) {
      throw "rtcIceServers missing";
    }

    let rtcConnection = new RTCPeerConnection({
      iceServers: this.rtcIceServers,
      iceTransportPolicy: "relay",
    });

    let connectionHolder = new ConnectionHolder(
      partnerId,
      rtcConnection,
      [],
      [],
      sessionId,
      username,
      name,
      recordingUserId,
      async () => {
        connectionHolder.connection.close();

        delete this.connectionDictionary[partnerId];

        this.sendConnectionsUpdated();

        await this.hubConnection?.invoke("unassign", partnerId);
      },
      () => {
        connectionHolder.connection.restartIce();

        connectionHolder.connection.getStats().then((s) => {});

        this.createOffer(partnerId, rtcConnection);
      }
    );

    rtcConnection.ontrack = (evt) => {
      console.log(
        "WebRTC: On track: " +
          JSON.stringify({
            id: evt.track.id,
            kind: evt.track.kind,
            label: evt.track.label,
          })
      );

      if (evt.track.kind === "video") {
        console.log(evt.track.label);
        connectionHolder.videostreams.push(new MediaStream([evt.track]));

        this.sendConnectionsUpdated();

        setTimeout(() => this.sendConnectionsUpdated(), 1000);
      }

      if (evt.track.kind === "audio") {
        console.log(evt.track.label);
        connectionHolder.audiostreams.push(new MediaStream([evt.track]));

        this.sendConnectionsUpdated();

        setTimeout(() => this.sendConnectionsUpdated(), 1000);
      }
    };

    rtcConnection.onicecandidate = async (evt) => {
      console.log("WebRTC: Ice Candidate callback");

      if (evt.candidate) {
        // Found a new candidate
        console.log("WebRTC: new ICE candidate: " + JSON.stringify(evt.candidate));

        if (this.hubConnection) {
          await this.hubConnection.invoke("sendSignal", JSON.stringify({ candidate: evt.candidate || null }), partnerId);
        }
      } else {
        // Null candidate means we are done collecting candidates.
        console.log("WebRTC: ICE candidate gathering complete");
      }
    };

    this.createOffer(partnerId, rtcConnection);

    this.connectionDictionary[partnerId] = connectionHolder;
    this.pendingUsers = this.pendingUsers.filter((u) => u.connectionId !== partnerId);

    this.sendConnectionsUpdated();
    this.sendPendingUpdated();

    return connectionHolder;
  };

  createOffer = async (partnerId: string, rtcConnection: RTCPeerConnection) => {
    console.log("createOffer");
    rtcConnection
      .createOffer({ offerToReceiveVideo: true })
      .then((offer) => {
        console.log("WebRTC: created Offer: ");
        console.log("WebRTC: Description after offer: ", offer);
        rtcConnection
          .setLocalDescription(offer)
          .then(() => {
            console.log("WebRTC: set Local Description: ");
            console.log("connection before sending offer ", rtcConnection);
            setTimeout(async () => {
              if (this.hubConnection) {
                await this.hubConnection.invoke("sendSignal", JSON.stringify({ sdp: rtcConnection.localDescription }), partnerId);
              }
            }, 1000);
          })
          .catch((err) => console.error("WebRTC: Error while setting local description", err));
      })
      .catch((err) => console.error("WebRTC: Error while creating offer", err));
  };

  start = async () => {
    if (this.hubConnection !== null) {
      return; // already startet
    }

    this.hubConnection = await getStreamingHubConnection();

    this.hubConnection.on("receiveSignal", async (partnerClientId: partnerConnectionId, data) => {
      console.log("WebRTC: called newSignal");
      //console.log('connections: ', connections);

      var signal = JSON.parse(data);
      let connection = this.getConnection(partnerClientId).connection;
      //console.log("signal: ", signal);
      //console.log("signal: ", signal.sdp || signal.candidate);
      //console.log("partnerClientId: ", partnerClientId);
      console.log("connection: ", connection);

      // Route signal based on type
      if (signal.sdp) {
        console.log("WebRTC: sdp signal");

        console.log("sdp", signal.sdp);
        console.log("WebRTC: called receivedSdpSignal");
        console.log("WebRTC: processing sdp signal");
        await connection.setRemoteDescription(new RTCSessionDescription(signal.sdp));

        console.log("WebRTC: set Remote Description");
        if (connection.remoteDescription?.type == "offer") {
          console.log("WebRTC: remote Description type offer");

          // for (let stream of streams) {
          //     for(let track of stream.getTracks())
          //     {
          //         connection.addTrack(track,stream);
          //         console.log("addTrack: " + track.label);
          //     }
          // }

          console.log("WebRTC: added stream");
          let desc = await connection.createAnswer();
          console.log("WebRTC: create Answer...");
          await connection.setLocalDescription(desc);
          console.log("WebRTC: set Local Description...");
          console.log("connection.localDescription: ", connection.localDescription);

          await this.hubConnection?.invoke("sendSignal", JSON.stringify({ sdp: connection.localDescription }), partnerClientId);
        } else if (connection.remoteDescription?.type == "answer") {
          console.log("WebRTC: remote Description type answer");
        }
      } else if (signal.candidate) {
        console.log("WebRTC: candidate signal");

        await connection.addIceCandidate(new RTCIceCandidate(signal.candidate));
        console.log("WebRTC: added candidate successfully");
        // , () => , () => console.log("WebRTC: cannot add candidate"));
      } else {
        console.log("WebRTC: adding null candidate");
        await connection.addIceCandidate({});
        console.log("WebRTC: added null candidate successfully");
      }
    });

    this.hubConnection.on("StopStreamingBadConnection", async (sessionId: number) => {
      console.log("Received StopStreamingBadConnection: " + JSON.stringify(sessionId));

      debugger;
      if(this.stopStreamingBadConnection)
      {
        this.stopStreamingBadConnection(sessionId);
      }
    });

    this.hubConnection.on(
      "UpdateUserList",
      async (
        userList: {
          username: string;
          name: string;
          connectionId: string;
          sessionId: number;
          recordingUserId: number;
          streamReviewerId: number | undefined | null;
        }[]
      ) => {
        console.log("Received UpdateUserList: " + JSON.stringify(userList));

        //   setCurrectUsers(userList);

        let toAdd = userList
          .filter((u) => this.pendingUsers.filter((p) => p.connectionId === u.connectionId).length === 0)
          .filter((u) => this.getConnections().filter((p) => p.partnerId === u.connectionId).length === 0);

        for (let add of toAdd) {
          if (add.streamReviewerId === ApiClient.currentUser.reviewerId) {
            this.getConnection(add.connectionId, add.username, add.name, add.sessionId, add.recordingUserId);
          } else {
            this.pendingUsers.push(add);
          }
        }

        this.sendPendingUpdated();

        // for (let user of userList) {
        //   console.log(
        //     "Trying to connection to: " + JSON.stringify(user.connectionId)
        //   );

        //   let rtcConnection = this.getConnection(
        //     user.connectionId,
        //     user.sessionId
        //   ).connection;
        // }
      }
    );

    await this.hubConnection.start();

    await this.hubConnection.invoke("GetUserList");
  };

  stop = async () => {
    try {
      await this.hubConnection?.stop();
    } catch {}

    for (let connection of this.getConnections()) {
      try {
        connection.connection.close();
      } catch {
        console.log("Failed to close connection");
      }
    }

    this.hubConnection = null;
    this.connectionDictionary = {};
  };
}
