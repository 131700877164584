import { useContext, useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ChatRole, ChatState } from "../../Api";
import { ApiClient, ChatRoleViewModel, ChatStateViewModel, FullChatViewModel } from "../../ApiHelper";
import AppContext from "../../AppContext";

function ChatStatusFields(props: { chat: FullChatViewModel }) {
  const chat = props.chat.chat;

  const [role, setRole] = useState<number>(chat.chatState.getIntValue());
  const [status, setStatus] = useState<number>(chat.chatState.getIntValue());
  const [assignedToReviewerId, setAssignedToReviewerId] = useState<number>(chat?.assignedToReviewerId || 0);

  useEffect(() => {
    setRole(chat.chatRole.getIntValue());
    setStatus(chat.chatState.getIntValue());
    setAssignedToReviewerId(chat?.assignedToReviewerId || 0);
    
  }, [chat?.chatRole, chat?.chatState, chat?.assignedToReviewerId]);

  async function roleChanged(event: SelectChangeEvent<number>) {
    var value = typeof event.target.value === "number" ? event.target.value : -1;
    setRole(value);

    var newValue = new ChatRoleViewModel(value as ChatRole);

    ApiClient.setChatFields(chat.id, chat.chatState, newValue, chat.assignedToReviewerId);
  }

  const myContext = useContext(AppContext);

  async function statusChanged(event: SelectChangeEvent<number>) {
    var value =
      typeof event.target.value === "number" ? event.target.value : -1;
    setStatus(value);

    var newValue = new ChatStateViewModel(value as ChatState);

    ApiClient.setChatFields(chat.id, newValue, chat.chatRole, chat.assignedToReviewerId);
  }

  async function assignedToReviewerIdChanged(event: SelectChangeEvent<number>) {
    var value = typeof event.target.value === "number" ? event.target.value : null;

    if(!value)
    {
        value = null;
    }

    setAssignedToReviewerId(value ?? 0);

    ApiClient.setChatFields(chat.id, chat.chatState, chat.chatRole, value);
  }

  return (
    <>
      <FormControl sx={{ m: 1 }}>
        <InputLabel>Status</InputLabel>
        <Select value={status} label="Status" onChange={statusChanged}>
          <MenuItem value={0}>Afventer KU</MenuItem>
          <MenuItem value={1}>Hos deltager/inaktiv</MenuItem>
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1 }}>
        <InputLabel>Tildelt rolle</InputLabel>
        <Select value={role} label="Tildelt rolle" onChange={roleChanged}>
          <MenuItem value={0}>Ikke tildelt</MenuItem>
          <MenuItem value={1}>Support</MenuItem>
          <MenuItem value={2}>Sagsbehandler</MenuItem>
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1 }}>
        <InputLabel>Tildelt person</InputLabel>
        <Select value={assignedToReviewerId} label="Tildelt rolle" onChange={assignedToReviewerIdChanged}>
          <MenuItem value={0}>Ikke tildelt</MenuItem>
          <MenuItem value={myContext!.reviewerId}>Mig</MenuItem>

          {chat?.assignedToReviewerId !== myContext!.reviewerId &&
            chat?.assignedToReviewerId && (
              <MenuItem value={chat?.assignedToReviewerId}>
                {chat?.assignedToReviewerName}
              </MenuItem>
            )}
        </Select>
      </FormControl>
    </>
  );
}

export default ChatStatusFields;
