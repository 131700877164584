import { Chip } from "@mui/material";
import { ChatStateViewModel } from "../../ApiHelper";

function ChatStateView(props: { value: ChatStateViewModel }) {
  var text = props.value.name;

  return <Chip label={text} style={{backgroundColor:props.value.backgroundColor, color: props.value.color}} size="small" />;
}

export default ChatStateView;
