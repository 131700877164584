import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import { useContext, useState } from "react";
import { ChatViewModel } from "../ApiHelper";
import AppContext from "../AppContext";
import ChatIntroduktionstekstEdit from "./chat/ChatIntroduktionstekstEdit";
import ChatList from "./chat/ChatList";

function ChatIndex() {
  const [role, setRole] = useState<number>(-1);
  const [status, setStatus] = useState<number>(-1);
  const [assignedToReviewer, setAssignedToReviewer] = useState<number>(-1);
  const [allowStreaming, setAllowStreaming] = useState<number>(-1);

  const myContext = useContext(AppContext);

  const reset = () => {
    setRole(-1);
    setStatus(-1);
    setAssignedToReviewer(-1);
  };

  const filter = (chat: ChatViewModel) => {
    if (role !== -1) {
      // vis ikke tildelte
      if (role === 0 && chat.chatRole.getIntValue() !== 0) return false;
      if (role !== 0 && role !== chat.chatRole.getIntValue()) return false;
    }
    if (status !== -1 && status !== chat.chatState.getIntValue()) return false;
    if (assignedToReviewer !== -1) {
      if (assignedToReviewer === 0) {
        // kun mine
        if (chat.assignedToReviewerId !== myContext!.reviewerId) {
          return false;
        }
      } else {
        // kun ikke-assigned
        if (chat.assignedToReviewerId !== null) {
          return false;
        }
      }
    }

    if(allowStreaming === 0 && chat.allowStreaming) return false;
    if(allowStreaming === 1 && !chat.allowStreaming) return false;

    return true;
  };

  return (
    <>
    
    <Paper elevation={1} sx={{ p: 2, mb: 1 }}>
      <Box sx={{ mb: 2, mt: 2, display: "flex", alignItems: "center" }}>
        <Box sx={{flexGrow: 1, display: "flex", alignItems: "center"}}>
          <FormControl sx={{ m: 1 }}>
            <InputLabel>Status</InputLabel>
            <Select
              value={status}
              label="Status"
              onChange={(event) =>
                setStatus(
                  typeof event.target.value === "number"
                    ? event.target.value
                    : -1
                )
              }
            >
              <MenuItem value={-1}>Vis alle</MenuItem>
              <MenuItem value={0}>Afventer KU</MenuItem>
              <MenuItem value={1}>Hos deltager/inaktiv</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1 }}>
            <InputLabel>Tildelt rolle</InputLabel>
            <Select
              value={role}
              label="Tildelt rolle"
              onChange={(event) =>
                setRole(
                  typeof event.target.value === "number"
                    ? event.target.value
                    : -1
                )
              }
            >
              <MenuItem value={-1}>Vis alle</MenuItem>
              <MenuItem value={0}>Vis ikke tildelte</MenuItem>
              <MenuItem value={1}>Support</MenuItem>
              <MenuItem value={2}>Sagsbehandler</MenuItem>
            </Select>
          </FormControl>

            <FormControl sx={{ m: 1 }}>
            <InputLabel>Live proctoring</InputLabel>
            <Select
                value={allowStreaming}
                label="Live proctoring"
                onChange={(event) =>
                setAllowStreaming(
                    typeof event.target.value === "number"
                    ? event.target.value
                    : -1
                )
                }
            >
                <MenuItem value={-1}>Vis alle</MenuItem>
                <MenuItem value={0}>Vis kun ansøgere uden live proctoring</MenuItem>
                <MenuItem value={1}>Vis kun ansøgere med live proctoring</MenuItem>
            </Select>
            </FormControl>

          <FormControl sx={{ m: 1 }}>
            <InputLabel>Tildelt person</InputLabel>
            <Select
              value={assignedToReviewer}
              label="Tildelt person"
              onChange={(event) =>
                setAssignedToReviewer(
                  typeof event.target.value === "number"
                    ? event.target.value
                    : -1
                )
              }
            >
              <MenuItem value={-1}>Vis alle</MenuItem>
              <MenuItem value={0}>Vis mine</MenuItem>
              <MenuItem value={1}>Vis kun ikke tildelte</MenuItem>
            </Select>
          </FormControl>

          <Button onClick={reset}>Nulstil</Button>
        </Box>
        <Box>
            <ChatIntroduktionstekstEdit />
        </Box>
      </Box>
      <Box sx={{ m: 1 }}>
        <ChatList filter={filter} />
      </Box>
      </Paper>
    </>
  );
}

export default ChatIndex;
