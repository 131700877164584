import React, { useState, useEffect, useRef, useContext } from "react";
import Button from "@mui/material/Button";
import {
  Chip,
  DialogContentText,
  Drawer,
  Link,
  Typography,
} from "@mui/material";
import { DataGrid, GridValueGetterParams } from "@mui/x-data-grid";
import ChatStateView from "./ChatStateView";
import ChatRoleView from "./ChatRoleView";
import {
  ApiClient,
  ChatViewModel,
  getChatHubConnection,
} from "../../ApiHelper";
import ChatLoad from "./ChatLoad";
import AppContext from "../../AppContext";
import { borderTop } from "@mui/system";
import { OpenRecordingUserChat } from "./OpenRecordingUserChat";
import NetworkTestResultView from "../NetworkTestResultView";

function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function ChatList(props: { filter: (chat: ChatViewModel) => boolean }) {
  const [chats, setChats] = useState<ChatViewModel[]>([]);

  const [currentChat, setCurrentChat] = useState<ChatViewModel | null>(null);

  const refreshChat = async (newid: number | null = null) => {
    var result = await ApiClient.getAllChats();

    result = result;

    setChats(result);

    if (newid) {
      let newChat = result.filter((c) => c.id === newid)[0];

      if (!newChat) {
        throw "Could not find new chat with id: " + newid;
      }
      setCurrentChat(newChat);
    } else {
      if (currentChat !== null) {
        setCurrentChat(result.filter((c) => c.id === currentChat.id)[0]);
      }
    }
  };

  const onClickRecordingUser = async (recordingUserId: number) => {
    setCurrentChat(null);

    // creates chat if needed...
    let chat = await ApiClient.getFullChatFromRecordingUser(recordingUserId);

    await refreshChat(chat?.chat.id);
  };

  const myContext = useContext(AppContext);

  useEffect(() => {
    (async () => {
      await refreshChat();
    })();

    var hubConnection = getChatHubConnection();

    hubConnection.on("chat", async () => {
      console.log("Received chat push");

      await refreshChat();
    });

    hubConnection.start();

    return () => {
      hubConnection.stop();
    };
  }, []);

  useInterval(() => refreshChat(), 30000);

  var columns = [
    {
      field: "open",
      headerName: "",
      renderCell: (params: GridValueGetterParams<any, ChatViewModel>) => {
        return (
          <Button variant="outlined" onClick={() => setCurrentChat(params.row)}>
            Åbn chat
          </Button>
        );
      },
    },
    {
      field: "username",
      headerName: "Brugernavn",
      numeric: false,
      disablePadding: true,
      width: 150,
    },
    {
      field: "name",
      headerName: "Navn",
      numeric: false,
      disablePadding: true,
      width: 300,
    },
    {
      field: "allowStreaming",
      headerName: "Tillad live proctoring",
      numeric: false,
      disablePadding: true,
      width: 300,
      valueGetter: (params: GridValueGetterParams<any, ChatViewModel>) =>
        params.row.allowStreaming ? "Ja" : "Nej",
    },
    {
      field: "networkTestResult",
      headerName: "Netværkstest",
      numeric: false,
      disablePadding: true,
      width: 300,
      renderCell: (params: GridValueGetterParams<any, ChatViewModel>) => {
        return <NetworkTestResultView recordingUserId={params.row.recordingUserId} value={params.row.networkTestResult} />;
      },
      valueGetter: (params: GridValueGetterParams<any, ChatViewModel>) => params.row.networkTestResult.state,
    },
    {
      field: "state",
      headerName: "Status",
      width: 150,
      renderCell: (params: GridValueGetterParams<any, ChatViewModel>) => {
        return <ChatStateView value={params.row.chatState || 0} />;
      },
      valueGetter: (params: GridValueGetterParams<any, ChatViewModel>) =>
        params.row.chatState,
    },
    {
      field: "role",
      headerName: "Rolle",
      width: 150,
      renderCell: (params: GridValueGetterParams<any, ChatViewModel>) => {
        return <ChatRoleView value={params.row.chatRole || 0} />;
      },
      valueGetter: (params: GridValueGetterParams<any, ChatViewModel>) =>
        params.row.chatRole,
    },
    {
      field: "person",
      headerName: "Tildelt person",
      width: 150,
      renderCell: (params: GridValueGetterParams<any, ChatViewModel>) => {
        var chat = params.row;

        if (chat.assignedToReviewerId === myContext?.reviewerId) {
          return <Chip label="Min" color="success" size="small" />;
        }

        if (chat.assignedToReviewerName) {
          return (
            <Chip
              label={chat.assignedToReviewerName}
              sx={{ backgroundColor: "#ADD8E6", color: "#F3F3F3" }}
              size="small"
            />
          );
        }

        return <></>;
      },
      valueGetter: (params: GridValueGetterParams<any, ChatViewModel>) =>
        params.row.assignedToReviewerName,
    },
    {
      field: "lastActivity",
      headerName: "Sidste aktivitet",
      width: 150,
      renderCell: (params: GridValueGetterParams<any, ChatViewModel>) => (
        <>{params.row.lastActivity?.toFormat("d/M kl. HH:mm") || ""}</>
      ),
      valueGetter: (params: GridValueGetterParams<any, ChatViewModel>) =>
        params.row.lastActivity?.toISO(),
    },
    {
      field: "exams",
      headerName: "Tilmeldinger",
      width: 250,
      renderCell: (params: GridValueGetterParams<any, ChatViewModel>) => (
        <>
          <div>
            {params.row.userExams.map((ue) => {
              return (
                <Typography key={ue.examineeId}>
                  {" "}
                  <Link
                    underline="hover"
                    color="inherit"
                    href={"/app/recordings/examinee/" + ue.examineeId}
                  >
                    {ue.name}
                  </Link>
                </Typography>
              );
            })}
          </div>
        </>
      ),
    },
  ];

  if (!chats.length) {
    return <>Loading...</>;
  }

  return (
    <>
      <OpenRecordingUserChat
        onClickRecordingUser={onClickRecordingUser}
      ></OpenRecordingUserChat>

      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            disableSelectionOnClick={true}
            autoHeight
            rows={chats
              .filter((s) => s.lastActivity !== null)
              .filter((c) => props.filter(c))}
            columns={columns}
          />
        </div>
      </div>

      <React.Fragment>
        <Drawer
          anchor="right"
          open={currentChat !== null}
          onClose={() => setCurrentChat(null)}
          PaperProps={{
            sx: { width: "50%" },
          }}
        >
          {currentChat && <ChatLoad chat={currentChat} />}
        </Drawer>
      </React.Fragment>
    </>
  );
}

export default ChatList;
