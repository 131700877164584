import { Add, Delete, Edit, Favorite, Navigation } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Breadcrumbs, Button, Card, CircularProgress, Fab, IconButton, Link } from "@mui/material";
import { useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useNavigate, useParams } from "react-router-dom";
import { SetAssignedReviewersInputModel } from "../Api";
import { ApiClient, ExamineeRowViewModel, ExamViewModel } from "../ApiHelper";
import NetworkTestResultView from "./NetworkTestResultView";

class State {
  constructor(public examinees: ExamineeRowViewModel[], public reviewers: ReviewerDto[]) {}

  copy = () => {
    return new State(this.examinees, this.reviewers);
  };

  public static Null = new State([], []);

  removeExaminee = (examinee: ExamineeRowViewModel) => {
    this.examinees = this.examinees.filter((e) => e.examineeId !== examinee.examineeId);

    for (let reviewer of this.reviewers) {
      reviewer.examinees = reviewer.examinees.filter((e) => e.examineeId !== examinee.examineeId);
    }
  };

  toModel = () => {
    let model: Record<string, number> = {};

    for (let reviewer of this.reviewers) {
      for (let examinee of reviewer.examinees) {
        model[examinee.examineeId] = reviewer.id;
      }
    }

    return model;
  };

  stringify = () => {
    return JSON.stringify(this.toModel());
  };
}

class Events {
  constructor(
    public onDropExamineeOnReviewer: (examinee: ExamineeRowViewModel, reviewerDto: ReviewerDto) => void,
    public onRemoveExamineeFromReviewer: (examinee: ExamineeRowViewModel) => void
  ) {}
}

export function DistributeReviewersRoute() {
  let { examId } = useParams();

  return <DistributeReviewers examId={parseInt(examId || "-1")} />;
}

function DistributeReviewers(props: { examId: number }) {
  const [lastSavedStringify, setLastSavedStringify] = useState("");
  const [currentState, setWorld] = useState(State.Null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [exam, setExam] = useState<ExamViewModel | null>(null);

  const loadData = async () => {
    let reviewers = await ApiClient.getAllReviewers();
    let exam = await ApiClient.getExam(props.examId);
    let examinees = exam.examinees.filter(e => e.allowStreaming)

    setExam(exam.exam);

    let newState = new State(
        examinees.filter((e) => e.streamReviewer === null),
      reviewers.map(
        (r) =>
          new ReviewerDto(
            r.id,
            r.name,
            examinees.filter((e) => e.streamReviewer?.id === r.id)
          )
      )
    );

    setWorld(newState);

    setLastSavedStringify(newState.stringify());

    setLoading(false);
  };

  const save = async () => {
    setSaving(true);
    let model: Record<string, number> = {};

    for (let reviewer of currentState.reviewers) {
      for (let examinee of reviewer.examinees) {
        model[examinee.examineeId] = reviewer.id;
      }
    }

    await ApiClient.api.exam.reviewerSetassignedUpdate(props.examId, {
      assignedReviewers: model,
    });
    await loadData();

    setSaving(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  function drop(examinee: ExamineeRowViewModel, reviewer: ReviewerDto) {
    setWorld((w) => {
      w = w.copy();

      w.removeExaminee(examinee);

      let currentReviewer = w.reviewers.filter((r) => r.id === reviewer.id)[0];

      currentReviewer.examinees.push(examinee);

      currentReviewer.examinees.sort((a, b) => a.name.localeCompare(b.name));

      console.log(JSON.stringify(w));

      return w;
    });
  }

  function resetExaminee(examinee: ExamineeRowViewModel) {
    setWorld((w) => {
      w = w.copy();

      w.removeExaminee(examinee);

      w.examinees.push(examinee);

      console.log(JSON.stringify(w));

      return w;
    });
  }

  var events = new Events(drop, resetExaminee);

  if (loading || !exam) {
    return <CircularProgress />;
  }

  let canSave = lastSavedStringify !== currentState.stringify();

  return (
    <>
    <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/app/recordings">
          Optagelser
        </Link>

        <Link
          underline="hover"
          color="inherit"
          href={"/app/recordings/" + exam.id}
        >
          {exam.name}
        </Link>

        <Link underline="hover" color="inherit">
          Fordel deltagere på tilsyn
        </Link>
      </Breadcrumbs>
      
      <Card style={{ margin: "10px", padding: "10px", width: "980px"}}>
        <div style={{marginBottom: "1em"}}>Træk deltagere over på tilsyn.</div>
        <LoadingButton onClick={save} loading={saving} variant="outlined" disabled={!canSave}>
          Gem
        </LoadingButton>
      </Card>

      <div style={{ display: "flex" }}>
        <div style={{ width: "500px", padding: "10px" }}>
          <Card style={{ padding: "10px" }}>
            Tilsyn
            {currentState.reviewers.map((r) => (
              <div key={"r" + r.id}>
                <Reviewer reviewer={r} events={events} />
              </div>
            ))}
          </Card>
        </div>
        <div style={{ width: "500px", padding: "10px" }}>
          <ExamineeZone events={events}>
            <>
              {currentState.examinees.length === 0 && (
                <div>
                  <div style={{ marginTop: "1em", color: "#666", fontStyle: "italic" }}>
                  Ingen live overvågningsdeltagere uden reviewer
                  </div>
                </div>
              )}

              {currentState.examinees.map((r) => (
                <Examinee examinee={r} key={"e" + r.examineeId} canDelete={false} events={events} />
              ))}
            </>
          </ExamineeZone>
        </div>
      </div>
    </>
  );
}

class ReviewerDto {
  constructor(public id: number, public name: string, public examinees: ExamineeRowViewModel[]) {}
}

function Reviewer(props: { reviewer: ReviewerDto; events: Events }) {
  const [collectedProps, drop] = useDrop<ExamineeRowViewModel>(() => ({
    accept: "EXAMINEE",
    drop: (examinee) => props.events.onDropExamineeOnReviewer(examinee, props.reviewer),
  }));

  return (
    <Card variant="outlined" ref={drop} style={{ padding: "10px", marginBottom: "10px" }}>
      <div>{props.reviewer.name}</div>

      <div style={{ paddingLeft: "20px" }}>
        {props.reviewer.examinees.map((e) => (
          <div key={"x" + e.examineeId}>
            <Examinee examinee={e} canDelete={true} events={props.events} />
          </div>
        ))}
      </div>
    </Card>
  );
}

function Examinee(props: { examinee: ExamineeRowViewModel; canDelete: boolean; events: Events }) {
  const [{ opacity, isDragging }, dragRef] = useDrag(
    () => ({
      type: "EXAMINEE",
      item: props.examinee,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
        isDragging: !!monitor.isDragging(),
      }),
    }),
    []
  );
  return (
    <Card variant="outlined" ref={dragRef} style={{ opacity, padding: "10px", marginBottom: "4px" }}>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}>{props.examinee.name}</div>

        <div><NetworkTestResultView recordingUserId={props.examinee.recordingUserId} value={props.examinee.networkTestResult} /></div>

        {props.canDelete && (
          <div>
            <IconButton size="small" onClick={() => props.events.onRemoveExamineeFromReviewer(props.examinee)}>
              <Delete fontSize="small" />
            </IconButton>
          </div>
        )}
      </div>
    </Card>
  );
}

function ExamineeZone(props: { children: JSX.Element; events: Events }) {
  const [collectedProps, drop] = useDrop<ExamineeRowViewModel>(() => ({
    accept: "EXAMINEE",
    drop: (examinee) => props.events.onRemoveExamineeFromReviewer(examinee),
  }));

  return (
    <Card style={{ padding: "10px", paddingBottom: "50px" }} ref={drop}>
      Deltagere uden reviewer
      {props.children}
    </Card>
  );
}

export default DistributeReviewers;
