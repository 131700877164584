import { Breadcrumbs, Button, Chip, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { DataGrid } from "@mui/x-data-grid/DataGrid/DataGrid";
import { GridValueGetterParams } from "@mui/x-data-grid/models/params/gridCellParams";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ExamineeRowModel, ExamWithExamineesModel } from "../Api";
import { ApiClient, ExamStatsViewModel, ExamWithExamineesViewModel } from "../ApiHelper";
import { ReviewStateView } from "./ReviewStateEdit";
import NetworkTestResultView from "./NetworkTestResultView";

function RecordingExam() {
  const [exam, setExam] = useState<null | ExamWithExamineesViewModel>(null);

  let { id, searchText } = useParams();

  useEffect(() => {
    (async () => {
      const exam = await ApiClient.getExam(parseInt(id || "0"));

      setExam(exam);
    })();
  }, []);

  if (exam === null) {
    return <CircularProgress />;
  }

  return <ExamDetails exam={exam} />;
}

function ExamDetails(props: { exam: ExamWithExamineesViewModel }) {
  const navigate = useNavigate();

  const handleClick = (id: any) => {
    navigate("/app/recordings/examinee/" + id);
  };

  const [exam, setExam] = useState(props.exam);

  let rows = exam.examinees;

  const [selectedValues, setSelectedValues] = useState<number[]>([]);

  if (!rows) {
    return <CircularProgress />;
  }

  const refresh = async () => {
    setExam(await ApiClient.getExam(exam.exam.id));
  };

  const onSelectChange = (id: number, value: boolean) => {
    setSelectedValues((v) => {
      if (value) {
        var copy = [...v];

        copy.push(id);

        return copy;
      } else {
        return v.filter((c) => c !== id);
      }
    });
  };

  const onToggleAll = (id: number, value: boolean) => {
    if (value) {
      setSelectedValues(rows.map((r) => r.examineeId));
    } else {
      {
        setSelectedValues([]);
      }
    }
  };

  const allSelected = () => {
    return rows.filter((r) => !selectedValues.includes(r.examineeId)).length === 0;
  };

  const anySelected = () => {
    return rows.filter((r) => selectedValues.includes(r.examineeId)).length > 0;
  };

  debugger;

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/app/recordings">
          Optagelser
        </Link>

        <Link underline="hover" color="inherit" href={"/app/recordings/" + exam.exam.id}>
          {props.exam.exam.name}
        </Link>
      </Breadcrumbs>

      <ExamineeActions examId={exam.exam.id} selectedExamineeIds={selectedValues} onSaved={refresh} />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <SelectIdBox id={-1} initialValue={rows.filter((r) => !selectedValues.includes(r.examineeId)).length === 0} onChange={onToggleAll} />
              </TableCell>
              <TableCell>Brugernavn</TableCell>
              <TableCell>Navn</TableCell>
              <TableCell>Tillad live proctoring</TableCell>
              <TableCell>Netværkstest</TableCell>
              <TableCell>Live proctoring tilsyn</TableCell>
              <TableCell>Optagelsesstatus</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Kommentarer</TableCell>
              <TableCell>Bogmærker</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.username} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>
                  <SelectIdBox id={row.examineeId} initialValue={selectedValues.includes(row.examineeId)} onChange={onSelectChange} />
                </TableCell>
                <TableCell onClick={() => handleClick(row.examineeId)} component="th" scope="row">
                  {row.username}
                </TableCell>
                <TableCell onClick={() => handleClick(row.examineeId)}>{row.name}</TableCell>
                <TableCell>
                  <div>{row.allowStreaming ? "Ja" : "Nej"}</div>
                </TableCell>
                <TableCell>
                  <NetworkTestResultView recordingUserId={row.recordingUserId} value={row.networkTestResult} />
                </TableCell>
                <TableCell>
                  <div>{row.allowStreaming ? (row.streamReviewer ? row.streamReviewer.name : "(ingen)") : ""}</div>
                </TableCell>
                <TableCell onClick={() => handleClick(row.examineeId)}>
                 <LastActivity 
                 hasRecordings={row.lastActivity.hasRecordings!}
                 hasLoggedIn={row.lastActivity.hasLoggedIn!}
                 lastActivityInMinutes={row.lastActivity.lastActivityInMinutes!}
                 lastActivityInMinutesRelativeToNowOrExamEnd={row.lastActivity.lastActivityInMinutesRelativeToNowOrExamEnd!}
                   /> 
                </TableCell>
                <TableCell onClick={() => handleClick(row.examineeId)}>
                  <ReviewStateView reviewState={row.reviewState} />
                </TableCell>
                <TableCell onClick={() => handleClick(row.examineeId)}>{row.reviewState.comment?.length || 0}</TableCell>
                <TableCell onClick={() => handleClick(row.examineeId)}>{row.bookmarkCount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function LastActivity(props: { hasRecordings: boolean, hasLoggedIn: boolean, lastActivityInMinutes: number | null, lastActivityInMinutesRelativeToNowOrExamEnd: number | null }) {
  if (!props.hasLoggedIn) {
    return <Chip label="Ikke logget ind" size="small" color="error" />;
  }

  if (!props.hasRecordings) {
    return <Chip label="Intet data" size="small" color="error" />;
  }

  if (props.lastActivityInMinutesRelativeToNowOrExamEnd === null) {
    return <Chip label="Ikke logget ind" size="small" color="error" />;
  }

  let minutter = props.lastActivityInMinutesRelativeToNowOrExamEnd + " min";

  if (props.lastActivityInMinutesRelativeToNowOrExamEnd < 3) {
    return <Chip label={minutter} size="small" color="success" />;
  } else if (props.lastActivityInMinutesRelativeToNowOrExamEnd < 5) {
    return <Chip label={minutter} size="small" color="warning" />;
  } else {
    return <Chip label={minutter} size="small" color="error" />;
  }
}

function ExamineeActions(props: { examId: number; selectedExamineeIds: number[]; onSaved: () => void }) {
  const navigate = useNavigate();

  let disabled = props.selectedExamineeIds.length === 0;

  const setLiveProctoring = async (value: boolean) => {
    await ApiClient.examineeAllowstreaming(props.examId, props.selectedExamineeIds, value);
    props.onSaved();
  };

  return (
    <Paper elevation={1} sx={{ p: 2, mb: 1 }}>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: "1" }}>
          <Button variant="contained" disabled={disabled} onClick={() => setLiveProctoring(true)} sx={{ mr: 1 }}>
            Slå live proctoring til
          </Button>
          <Button variant="contained" disabled={disabled} onClick={() => setLiveProctoring(false)} sx={{ mr: 1 }}>
            Slå live proctoring fra
          </Button>
        </div>
        <div>

          <Button variant="contained" onClick={() => navigate(`/app/exam/${props.examId}/reviewers/distribute`)}>
            Fordel deltagere på tilsyn
          </Button>
        </div>
      </div>
    </Paper>
  );
}

function SelectIdBox(props: { id: number; initialValue: boolean; onChange: (id: number, value: boolean) => void }) {
  const onChange = () => {
    props.onChange(props.id, !props.initialValue);
  };

  return <input type="checkbox" checked={props.initialValue} onChange={onChange} />;
}

export default RecordingExam;
