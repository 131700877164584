import { DateTime } from "luxon";
import { Chip, CircularProgress, Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import { ApiClient, NetworkTestResultViewModel } from "../ApiHelper";
import { useEffect, useState } from "react";
import { NetworkTest, NetworkTestModel } from "../Api";

function NetworkTestResultView(props: { recordingUserId: number; value: NetworkTestResultViewModel }) {
  const getChip = (result: NetworkTestResultViewModel) => {
    switch (result.state) {
      case "success":
        return <Chip label="OK" size="small" color="success" />;

      case "warning":
        return <Chip label="Nedsat hastighed" size="small" color="warning" />;

      case "error":
        return <Chip label="Dårlig hastighed" size="small" color="error" />;

      case "no-results":
        return <Chip label="Ikke testet" size="small" />;
    }
  };

  const [tests, setTests] = useState<NetworkTestModel[] | null>(null);
  const onOpen = async () => {
    var tests = await ApiClient.getNetworkTests(props.recordingUserId);

    setTests(tests);
  };

  var title = <CircularProgress color="inherit" size="1rem" />;

  if (tests != null) {
    if (tests.length === 0) {
      title = <div>Ingen netværkstests udført endnu</div>;
    } else {
      title = (
        <div>
          <table>
            <tr>
              <th>Session</th>
              <th>Systemtjek</th>
              <th>Tidspunkt</th>
              <th>Download resultat</th>
              <th>Download kbit/s</th>
              <th>Upload resultat</th>
              <th>Upload kbit/s</th>
              <th>Ping resultat</th>
              <th>Ping ms</th>
            </tr>
            {tests.map((x) => (
              <tr key={x.id!}>
                <th>{DateTime.fromISO(x.sessionCreated!).toFormat("d/M kl. HH:mm")}</th>
                <th>{x.systemCheck ? "Ja" : "Nej"}</th>
                <th>{DateTime.fromISO(x.created!).toFormat("d/M kl. HH:mm")}</th>
                <th>{getChip(new NetworkTestResultViewModel(x.downloadResult!))}</th>
                <th>{x.downloadKbitPerSecond}</th>
                <th>{getChip(new NetworkTestResultViewModel(x.uploadResult!))}</th>
                <th>{x.uploadKbitPerSecond}</th>
                <th>{getChip(new NetworkTestResultViewModel(x.pingResult!))}</th>
                <th>{x.pingMilliseconds}</th>
              </tr>
            ))}
          </table>
        </div>
      );
    }
  }

  return (
    <NoMaxWidthTooltip onOpen={onOpen} title={title}>
      {getChip(props.value)}
    </NoMaxWidthTooltip>
  );
}

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }}></Tooltip>)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});

export default NetworkTestResultView;
