import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { ApiClient } from "../../ApiHelper";
import { RecordingUserSearchResult } from "../../Api";

export function OpenRecordingUserChat(props: { onClickRecordingUser: (recordingUserId: number) => void}) {
  const [searchText, setSearchText] = useState("");

  const [users, setUsers] = useState<RecordingUserSearchResult[] | null>(null);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setUsers([]);
    setSearchText("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const search = async (event: any) => {
    event.preventDefault();
    var users = await ApiClient.api.chat.recordinguserSearchList({
      searchText: searchText,
    });

    setUsers(users);

    return true;
  };

  return (
    <>
      <div>
        <Box sx={{mb: 2}}>
            <Button variant="outlined" onClick={handleClickOpen}>
            Start chat med deltager
            </Button>
        </Box>
        <Dialog open={open} onClose={handleClose} fullWidth={true}>
          <DialogTitle>Åbn chat med deltager</DialogTitle>
          <DialogContent>
            <form onSubmit={search}>
              <Grid container={true}>
                <TextField
                  id="outlined-basic"
                  label="Outlined"
                  variant="outlined"
                  placeholder="Søg efter navn/brugernavn"
                  value={searchText}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchText(event.target.value);
                  }}
                />

                <Button onClick={search}>Søg efter deltager...</Button>
              </Grid>
            </form>

            {users !== null && (
              <>
                <Box sx={{ fontWeight: "bold", mt: 2 }}>Resultater</Box>

                {users.length === 0 && (
                  <>
                    <div>Ingen deltagere fundet.</div>
                  </>
                )}

                {users.length > 0 &&
                  users.map((u) => (
                    <div key={u.id}>
                      {u.name} {u.username}
                      <Button onClick={() => {
                        props.onClickRecordingUser(u.id || 0);
                        handleClose();
                    }
                      }> Start chat med {u.username}</Button>
                    </div>
                  ))}
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Luk</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
